import React from 'react'
import {
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from 'antd'
import { DatePickerWithMask } from '../../../../components'
import { timeZones } from '../../../../redux/constants'

const IDClass = ({ communicationType, handleClearDateTime, handleClearTimeZones }) => {
  const [atualizar, setAtualizar] = React.useState(false)

  const [enviarFacial, setEnviarFacial] = React.useState(false);

  const handleEnableFacialBiometrics = (checked) => {
    if (checked) {
      Modal.info({
        title: 'Recomendações para a foto!',
        content: (
          <div style={{ textAlign: 'justify' }}>
            <p>Para o cadastro facial ocorrer com sucesso a foto deve seguir as seguintes recomendações:</p>
            <ul>
              <li>A foto deve conter uma face que não esteja nem muito próxima nem muito distante. A região da face deve estar totalmente contida na foto, sendo mantida uma pequena margem em relação a cada um dos lados.</li>
              <li>A região da face deve ocupar o centro da imagem tanto na direção vertical quanto na horizontal.</li>
              <li>A pessoa na foto deve estar com a face voltada para a câmera, olhando para a frente.</li>
              <li>A região da face deve estar nítida e bem iluminada.</li>
            </ul>
          </div>
        ),
        maskClosable: false,
        keyboard: false,
        closable: false,
        width: 600,
      })
    }
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title="Dados de comunicação">
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="numero_serial_rep"
              label="Número de série"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Número de série" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="ultimo_nsr"
              label="Último NSR no equipamento"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <InputNumber
                type="number"
                placeholder="Último NSR no equipamento"
                min="1"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idface[ip]"
              label="IP"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="IP" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idface[porta_tcp_ip]"
              label="Porta TCP/IP"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <InputNumber
                type="number"
                placeholder="Porta TCP/IP"
                min="1"
                style={{ width: '100%' }}
                disabled={communicationType !== 'pendrive' ? false : true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="configuracao_idface[gateway]" label="Gateway">
              <Input placeholder="Gateway" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idface[mascara_sub_rede]"
              label="Máscara de sub rede"
            >
              <Input placeholder="Máscara de sub rede" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_idface[usuario]"
              label="Usuário"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input placeholder="Usuário" disabled={communicationType !== 'pendrive' ? false : true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="configuracao_idface[senha]"
              label="Senha"
              hasFeedback
              rules={[{ required: communicationType === 'tcp_ip', message: 'Campo obrigatório.' }]}
            >
              <Input
                type="password"
                placeholder="Senha"
                autoComplete="new-password"
                disabled={communicationType !== 'pendrive' ? false : true}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              name="configuracao_idface[tipo_documento]"
              label="Tipo de comunicação"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                placeholder="Selecione o tipo de comunicação"
              >
                <Select.Option value="cpf">CPF</Select.Option>
                <Select.Option value="pis">PIS/PASEP</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title="Outros">
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              name="configuracao_idface[enviar_avatar_facial]"
              label="Utilizar avatar do funcionário para cadastro de biometria facial"
              valuePropName="checked"
            >
              <Switch
                value={enviarFacial}
                checked={enviarFacial}
                onChange={(value) => {
                  setEnviarFacial(!enviarFacial);
                  handleEnableFacialBiometrics(value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idface[atualizar_data_hora]"
              label="Atualizar data e hora do relógio"
              valuePropName="checked"
            >
              <Switch
                value={atualizar}
                onChange={() => {
                  setAtualizar(!atualizar)
                  handleClearDateTime()
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <DatePickerWithMask
              label="Data e hora"
              name="configuracao_idface[data_hora]"
              showTime={true}
              disabled={!atualizar}
            />
          </Col>
          {/*TODO validar se realmente da pra usar RFID no idFace */}
          {/* <Col xs={24} md={12}>
            <Form.Item
              name="configuracao_idface[formato_rfid]"
              label="Formato RFID"
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (typeof option.children === 'string'
                    ? option.children
                    : option.children.props.children
                  )
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                placeholder="Formato RFID"
                showSearch
              >
                <Select.Option value="decimal">Decimal</Select.Option>
                <Select.Option value="simples">
                  Simples (com virgula)
                </Select.Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col xs={24}>
            <Form.Item
              name="configuracao_idface[fuso_horario]"
              label="Fuso horário"
              hasFeedback
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (typeof option.children === 'string'
                    ? option.children
                    : option.children.props.children
                  )
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                placeholder="Selecione o fuso horário"
                showSearch
              >
                {timeZones.map((item) => (
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Space>
  )
}

export default IDClass
