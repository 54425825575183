import React, { Component } from "react";
import { Col, Modal, Spin, Row, Empty, Button } from "antd";
import moment from "moment";
import qs from 'querystring';
import { connect } from "react-redux";
import { reportService } from "./../../../redux/services";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			data_inicio: "",
			data_fim: "",
			tableData: [],
			groupBy: 0,
		};
	}

	componentDidMount() {
		document.body.classList.add("page-print", "page-print-espelho-ponto");
		this.fetchGetAll();
	}

	componentWillUnmount() {
		document.body.classList.remove("page-print", "page-print-espelho-ponto");
	}

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		const params = qs.parse(this.props.location.search.replace('?', ''))

		const data = {};

		if (params?.empresas && params?.empresas !== 'todos') {
			this.setState({
				empresa_id: params?.empresas
			})

			data.empresas = params?.empresas;

			data.empresas = Array.isArray(params?.empresas)
				? params?.empresas
				: [params?.empresas];
		}

		if (params?.departamentos && params?.departamentos !== 'todos') {
			data.departamentos = Array.isArray(params?.departamentos)
			? params?.departamentos
			: [params?.departamentos];
		}

		if (params?.funcionarios && params?.funcionarios !== 'todos') {
			data.funcionarios = Array.isArray(params?.funcionarios)
			? params?.funcionarios
			: [params?.funcionarios];
	}

		if (params?.jornadas && params?.jornadas !== 'todos') {
			data.jornadas = Array.isArray(params?.jornadas)
			? params?.jornadas
			: [params?.jornadas];
		}

		if (params?.justificativas && params?.justificativas !== 'todos') {
			data.justificativas = Array.isArray(params?.justificativas)
			? params?.justificativas
			: [params?.justificativas];
		}
		
		if (params?.data_inicio) {
			data.data_inicio = params?.data_inicio;
      this.setState({
				data_inicio: params?.data_inicio,
      })
    } else {
			Modal.error({
				title: 'Ocorreu um erro!',
        content: 'Data inicial não encontrada!',
        maskClosable: false,
        keyboard: false,
        closable: false,
        onOk: () => {
					window.close();
        },
      })
      return false
    }
		
    if (params?.data_fim) {
			data.data_fim = params?.data_fim;
      this.setState({
				data_fim: params?.data_fim,
      })
    } else {
      Modal.error({
        title: 'Ocorreu um erro!',
        content: 'Data final não encontrada!',
        maskClosable: false,
        keyboard: false,
        closable: false,
        onOk: () => {
					window.close();
        },
      })
      return false
    }
		
		if (params?.includeMissedHoursAbsence) {
			data.includeMissedHoursAbsence = params?.includeMissedHoursAbsence;
		}

		if (params?.includeInactiveEmployees) {
			data.includeInactiveEmployees = params?.includeInactiveEmployees;
		}

		if (params?.includePartialJustifications) {
			data.includePartialJustifications = params?.includePartialJustifications;
		}

		if (params?.groupBy !== undefined) {
			this.setState({
				groupBy: params?.groupBy
			})
			
			if (params?.groupBy === '1') data.por = 'departamento';
			if (params?.groupBy === '2') data.por = 'funcionario';
			if (params?.groupBy === '3') data.por = 'jornada';			
		}
		
		reportService
		.absenteeism(data)
			.then((response) => {
				const results = response.data.map((result) => ({
					empresa: result.empresa,
					lista: result.lista,
					totals: result.totals,
					media: result.media,
				}));

				this.setState({
					isLoading: false,
					tableData: results,
				});

				document.title = "Absenteísmo";
				setTimeout(() => window.print(), 1000);
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				Modal.error({ title: "Ocorreu um erro!", content: String(error) });
			});
	};

	handleMissingDataError = (message) => {
		Modal.error({
			title: "Ocorreu um erro!",
			content: message,
			maskClosable: false,
			keyboard: false,
			closable: false,
			onOk: () => window.close(),
		});
	};

	getGroupByValue = (groupBy) => {
		switch (groupBy) {
			case "1":
				return "departamento";
			case "2":
				return "funcionario";
			case "3":
				return "jornada";
			default:
				return "";
		}
	};

	groupByName = (groupBy) => {
		switch (groupBy) {
			case "1":
				return "Departamento";
			case "2":
				return "Funcionário";
			case "3":
				return "Horário";
			default:
				return "";
		}
	};

	render() {
		const { isLoading, tableData, groupBy, data_inicio, data_fim } = this.state;
		const { user } = this.props;
		const { name: userName } = user || {};
		const groupByName = this.groupByName(groupBy);
		const style = `@page { size: A4 landscape; margin: 0 }`

		if (isLoading) {
			return (
				<div
					style={{
						position: 'fixed',
						top: '150px',
						left: '50%',
						transform: 'translateX(-50%)',
					}}
				>
					<Spin
						spinning={isLoading}
						indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />}
					/>
				</div>
			);
		}

		if (tableData.length === 0) {
			return (
				<Empty
					description="Nenhum registro encontrado com esses parâmetros."
					style={{
						padding: "20px",
						position: "absolute",
						top: "20px",
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					<Button type="primary" onClick={() => window.close()}>
						Voltar
					</Button>
				</Empty>
			);
		}

		return (
			<>
				{tableData.map((empresaData, indexEmpresa) => {
					const { empresa, lista, totals, media } = empresaData;
					const taxVar = empresa?.cnpj ? "CNPJ" : "CPF";
					const taxValue = empresa?.cnpj || empresa?.cpf;
					const pageBreakAfter = tableData?.length > 1;
					const tableClass = `table-impressao cartao-ponto-${indexEmpresa} ml-10px ${pageBreakAfter ? 'page-break-after' : ''}`;

					return (
						<table key={indexEmpresa} className={tableClass}>
							<thead>
								<tr>
									<td className="time-card">
										<header className="mr-20px">
											<div className="logo-container">
												<div style={{ marginTop: 10 }}>
													<img
														src="/images/logos/logo-complete.svg"
														alt="ByPonto"
														width="180"
													/>
												</div>
												<div className="logo-title">
													<h1>Absenteísmo</h1>
													<div style={{ gap: '5px' }}>
														<div>
															Período: {moment(data_inicio).format("DD/MM/YYYY")} até{" "}
															{moment(data_fim).format("DD/MM/YYYY")}
														</div>
														<div>Agrupado por {groupByName}</div>
													</div>
													<div className="user-info" style={{ marginTop: '5px' }}>
														<h4>{userName} (Logado | {moment().format("DD/MM/YYYY HH:mm")})</h4>
													</div>
												</div>
											</div>
											<div className="info-container">
												<Row gutter={16}>
													<Col span={12}>
														<Row className="row-section-50">
															<Col span={24}>
																<div className="row-section-flex">
																	<strong>EMPRESA:</strong> {empresa?.nome}
																</div>
															</Col>
															<Col span={12}>
																<div className="row-section-flex">
																	<strong>{taxVar}:</strong> {taxValue}
																</div>
															</Col>
															<Col span={12}>
																<div className="row-section-flex">
																	<strong>INSCRIÇÃO:</strong> {empresa?.ie}
																</div>
															</Col>
														</Row>
													</Col>
												</Row>
											</div>
										</header>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<main>
											<div className="table-container" style={{ marginTop: "5px" }}>
												<table className="responsive-table">
													<thead>
														<tr>
															<th>{groupByName}</th>
															<th>Previstas</th>
															<th>Trabalhadas</th>
															<th>Ausências</th>
															<th>% das Ausências</th>
														</tr>
													</thead>
													<tbody>
														{lista.map((item, indexItem) => (
															<tr key={indexItem}>
																<td>{groupBy === '3' ? item?.descricao : item?.nome}</td>
																<td>{item?.horasPrevistas}</td>
																<td>{item?.horasTrabalhadas}</td>
																<td>{item?.ausencia}</td>
																<td>{(item?.percentual_ausencia ?? '0.00')}</td>
															</tr>
														))}
														<tr>
															<td className="negrito" style={{ fontSize: 12 }}>TOTAL</td>
															<td className="negrito" style={{ fontSize: 11 }}>{totals?.horasPrevistas}</td>
															<td className="negrito" style={{ fontSize: 11 }}>{totals?.horasTrabalhadas}</td>
															<td className="negrito" style={{ fontSize: 11 }}>{totals?.ausencia}</td>
															<td className="negrito" style={{ fontSize: 11 }}>{totals?.percentual_ausencia}</td>
														</tr>
														<tr>
															<td className="negrito" style={{ fontSize: 12 }}>MÉDIA</td>
															<td></td>
															<td></td>
															<td></td>
															<td className="negrito" style={{ fontSize: 12 }}>{media}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</main>
									</td>
								</tr>
							</tbody>
						</table>
					);
				})}
				<style dangerouslySetInnerHTML={{ __html: style }} />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.userData,
});

export default connect(mapStateToProps)(Index);
